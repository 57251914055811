import React, { Fragment, useContext } from "react"
import { useQuery } from "@apollo/client"
import styled from "styled-components"
import Loader from "shared/components/Loader"
import TeamSelector from "mill/containers/TeamSelector"
import TeamGroupSelector from "mill/containers/TeamGroupSelector"

import Heading from "shared/components/Heading"
import Text from "shared/components/Text"
import Wrapper from "shared/components/Wrapper"
import Box from "shared/components/Box"
import Button from "shared/components/Button"
import FilterBar from "shared/components/FilterBar"
import SearchSelector from "shared/containers/SearchSelector"
import SortBySelector from "shared/containers/SortBySelector"
import Modal from "shared/components/Modal"
import { lightGray } from "mill/utils/colors"
import { Flex } from "mill/utils/shared/core"
import useModal from "shared/hooks/useModal"
import usePageTitle from "mill/hooks/usePageTitle"
import useTrackPage from "mill/hooks/useTrackPage"
import FeatureFlagsContext from "mill/utils/FeatureFlagsContext"
import BulkLearnerUploader from "mill/containers/Admin/BulkLearnerUploader"
import SingleLearnerForm from "mill/containers/Admin/SingleLearnerForm"
import LearnersList from "mill/containers/Admin/LearnersList"
import LearnerTabs from "mill/components/Admin/LearnerTabs"
import EmptyState from "mill/components/EmptyState"
import TotalCountDisplay from "shared/components/TotalCountDisplay"
import { useFilterStateReducer } from "shared/hooks/useSelector"
import { sm } from "mill/utils/breakpoints"

import FETCH_ACTIVE_LEARNER_COUNT from "mill/graphql/FetchActiveLearnerCount.graphql"

const ModalContainer = styled.div`
  flex: 1;
  margin: 0 auto;
  background: white;
  display: flex;
`

const ModalHalf = styled.div`
  flex: 1;
  padding: 2rem;
  background-color: ${props => (props.grey ? `${lightGray}` : "white")};
  position: relative;
  ${({ desktopOnly }) =>
    desktopOnly &&
    `
    display: none;
    @media ${sm} {
      display: block;
    }
  `}
`

export const LearnerFilterContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 3rem;
`

const sortOptions = [
  { label: "Name (A→Z)", value: "users.name asc" },
  { label: "Name (Z→A)", value: "users.name desc" },
  { label: "Email (A→Z)", value: "users.email asc" },
  { label: "Email (Z→A)", value: "users.email desc" },
  { label: "Job title (A→Z)", value: "users.jobTitle asc" },
  { label: "Job title (Z→A)", value: "users.jobTitle desc" }
]

const LearnersPage = props => {
  usePageTitle("Learners")
  useTrackPage("Learners Index")
  const { modalOpen, toggleModal } = useModal()
  const { loading, error, data, refetch } = useQuery(FETCH_ACTIVE_LEARNER_COUNT)
  const {
    featureFlags: { emailless }
  } = useContext(FeatureFlagsContext)
  const initiallyShowFilterBar = useFilterStateReducer(["teamIds", "groupIds"])

  if (loading || !data) return <Loader />
  if (error) return <p>Error :(</p>

  const hasNonOwnerLearners = data.viewer.account.nonOwnerLearnerCount > 0

  return (
    <Flex>
      <Modal
        open={modalOpen}
        closeModal={toggleModal}
        maxWidth={"90rem"}
        noPadding
        showCloseIcon>
        <ModalContainer>
          <ModalHalf grey>
            <BulkLearnerUploader accountId={data.viewer.account.id} />
          </ModalHalf>
          <ModalHalf>
            <Heading level={2}>Add Individual Learner</Heading>
            <SingleLearnerForm
              handleClose={toggleModal}
              currentUser={props.currentUser}
              refetchQuery={refetch}
            />
          </ModalHalf>
        </ModalContainer>
      </Modal>

      <LearnerTabs activePage="learners" />

      <Wrapper>
        <Box
          display={{ mobile: "block", tablet: "flex" }}
          justifyContent="space-between"
          alignItems="center"
          paddingTop="large"
          paddingBottom="large"
          data-qa="learners-header">
          <Box marginBottom={{ mobile: "large", tablet: "none" }}>
            <Heading level={1}>
              Learners
              <TotalCountDisplay
                count={data.viewer.account.learnerCount}
                model="learner"
              />
            </Heading>
          </Box>
          {!emailless && (
            <Button
              color="secondary"
              onClick={toggleModal}
              label={"New learner"}
            />
          )}
        </Box>

        {hasNonOwnerLearners ? (
          <React.Fragment>
            <FilterBar
              initiallyOpen={initiallyShowFilterBar}
              visibleComponent={
                <Fragment>
                  <SearchSelector
                    autoFocus={true}
                    placeholder={"Find a learner"}
                  />
                  <SortBySelector options={sortOptions} />
                </Fragment>
              }>
              <LearnerFilterContainer>
                <TeamSelector placeholder="All teams" />
                <TeamGroupSelector placeholder="All groups" />
              </LearnerFilterContainer>
            </FilterBar>

            <LearnersList />
          </React.Fragment>
        ) : (
          <EmptyState imagePath="static/empty-state/learners.png?auto=format,compress">
            <Box paddingTop="xsmall" paddingBottom="xsmall">
              <Heading level={2}>Create your first learner</Heading>
            </Box>
            <Box paddingBottom={emailless ? "none" : "xsmall"}>
              <Text tone="dark">
                Looks like you don't have any learners yet.
              </Text>
            </Box>
            {!emailless && (
              <Button
                color="secondary"
                onClick={toggleModal}
                label="New learner"
              />
            )}
          </EmptyState>
        )}
      </Wrapper>
    </Flex>
  )
}

export default LearnersPage
