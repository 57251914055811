import React, { useContext, useState } from "react"
import FeatureFlagsContext from "mill/utils/FeatureFlagsContext"
import styled from "styled-components"
import { useMutation } from "@apollo/client"
import { Formik, Form, Field } from "formik"
import Select from "shared/components/FormSelect"
import Button from "shared/components/Button"
import { FormikTextField as TextField } from "mill/components/TextField"
import FormGroup from "mill/components/FormGroup"
import { LearnerSchema, LearnerSchemaWithUsername } from "mill/utils/schemas"
import { FormError } from "mill/utils/sharedStyles"
import { green } from "mill/utils/colors"
import useTeamOptions from "mill/hooks/useTeamOptions"

import CREATE_LEARNER from "mill/graphql/CreateLearner"

const SuccessContainer = styled.div`
  text-align: center;
  color: ${green};
  position: relative;

  i {
    font-size: 14rem;
    margin: 1rem 0;
  }

  button {
    width: 45%;
    float: left;

    &:first-of-type {
      margin-right: 10%;
    }
  }
`

const SingleLearnerForm = props => {
  const { featureFlags } = useContext(FeatureFlagsContext)
  const [lastCreatedUser, setLastCreatedUser] = useState(null)
  const [showForm, setShowForm] = useState(true)
  const { teamOptions, createTeam } = useTeamOptions()

  const [createLearner, { data }] = useMutation(CREATE_LEARNER, {
    refetchQueries: () => ["FetchLearners"],
    onCompleted: ({ createLearner }) => {
      if (createLearner.learner) {
        onAddLearnerComplete(createLearner.learner)
      }
    }
  })

  const showAddLearnerForm = () => setShowForm(true)

  const onDoneClick = () => {
    showAddLearnerForm()
    props.handleClose()
  }

  const onAddLearnerComplete = learner => {
    setLastCreatedUser(learner.name)
    setShowForm(false)
    props.refetchQuery()
  }

  const handleSubmit = (values, createLearner) => {
    const {
      name,
      email,
      jobTitle,
      teamIds,
      customIdentifier,
      username,
      mobileNumber
    } = values
    createLearner({
      variables: {
        attributes: {
          name,
          email,
          jobTitle,
          teamIds,
          customIdentifier,
          username,
          mobileNumber
        }
      }
    })
  }

  const schema = featureFlags.usernameLoginEnabled
    ? LearnerSchemaWithUsername
    : LearnerSchema

  const addAnotherLearnerPrompt = () => {
    return (
      <SuccessContainer>
        <p>
          {lastCreatedUser}
          <br /> was successfully added!
        </p>

        <p>
          <i className="fa fa-4x fa-check-circle" />
        </p>

        <Button
          type="submit"
          onClick={showAddLearnerForm}
          label={"Add another"}
          color="secondary"
          variant="hollow"
          fullWidth
        />

        <Button type="button" onClick={onDoneClick} label={"Done"} fullWidth />
      </SuccessContainer>
    )
  }

  if (!showForm) {
    return addAnotherLearnerPrompt()
  }

  return (
    <Formik
      initialValues={{
        name: "",
        email: "",
        jobTitle: "",
        teamIds: [],
        customIdentifier: "",
        username: ""
      }}
      validationSchema={schema}
      onSubmit={(values, actions) => {
        handleSubmit(values, createLearner)
        actions.setSubmitting(false)
      }}>
      {({ isSubmitting, setFieldValue, values }) => {
        const handleCreateTeam = teamName => {
          createTeam({
            variables: {
              name: teamName
            }
          }).then(
            ({
              data: {
                createTeam: { team }
              }
            }) => {
              setFieldValue("teamIds", [...values.teamIds, team.id])
            }
          )
        }
        return (
          <Form data-qa="single-learner-form">
            {data &&
              data.createLearner &&
              data.createLearner.errors.map((error, index) => {
                return <FormError key={`e-${index}`}>{error}</FormError>
              })}
            <Field
              withFieldset
              fullWidth
              name={"name"}
              type="text"
              component={TextField}
              label="Name"
              helpText="The learner's first and last name."
              placeholder="Larry Longbottom"
            />
            {featureFlags.usernameLoginEnabled && (
              <Field
                withFieldset
                fullWidth
                name={"username"}
                type="text"
                component={TextField}
                label="Username"
                placeholder="larry"
              />
            )}
            <Field
              withFieldset
              fullWidth
              name={"email"}
              autoCapitalize={"off"}
              type="text"
              component={TextField}
              label="Email"
              placeholder="super.seneca@yarno.com.au"
            />

            <Field
              withFieldset
              fullWidth
              name={"jobTitle"}
              type="text"
              component={TextField}
              label="Job title"
              helpText="What is the job title / role of the learner?"
              placeholder="Learning Specialist"
            />
            {featureFlags.customIdentifierEnabled && (
              <Field
                withFieldset
                fullWidth
                name={"customIdentifier"}
                type="text"
                component={TextField}
                label="Employee number"
                placeholder="123456"
              />
            )}
            {featureFlags.mobileNumbersEnabled && (
              <Field
                withFieldset
                fullWidth
                name={"mobileNumber"}
                type="text"
                component={TextField}
                label="Mobile number"
                placeholder="04xx xxx xxx"
              />
            )}
            <Field
              allowCreate
              withFieldset
              fullWidth
              name={"teamIds"}
              type="select"
              component={Select}
              label="Team(s)"
              helpText={"Add a learner to as many teams as you like."}
              placeholder="L&D, Management"
              isMulti
              menuPlacement="top"
              options={teamOptions}
              onCreateOption={handleCreateTeam}
            />
            <FormGroup marginTop={"1.5"}>
              <Button
                type="submit"
                label={"Add new learner"}
                color="primary"
                loading={isSubmitting}
                disabled={isSubmitting}
                fullWidth
              />
            </FormGroup>
          </Form>
        )
      }}
    </Formik>
  )
}

export default SingleLearnerForm
