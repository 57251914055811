import React, { useState } from "react"
import { useMutation, useLazyQuery } from "@apollo/client"
import { useDropzone } from "react-dropzone"
import Box from "shared/components/Box"
import Heading from "shared/components/Heading"
import Button from "shared/components/Button"
import Text from "shared/components/Text"
import FormGroup from "mill/components/FormGroup"
import { SecondaryHeader, DownloadLink } from "mill/utils/sharedStyles"
import ProgressBar from "mill/components/ProgressBar"
import styled from "styled-components"

export const ErrorRow = styled.small`
  display: block;
  color: ${props => props.theme.colors.error};
`
export const Errors = styled.div`
  margin-top: 1rem;
`

import POLL_BATCH_STATUS from "mill/graphql/PollBatchStatus"
import CREATE_PRESIGNED_URL from "mill/graphql/CreatePresignedUrl"
import BULK_CREATE_LEARNERS from "mill/graphql/BulkCreateLearners"
import uploadToSignedURL from "mill/utils/uploadToSignedUrl"
import useReport from "mill/hooks/useReport"

const BulkLearnerUploader = ({ accountId }) => {
  const [errors, setErrors] = useState([])
  const [uploadDisabled, setUploadDisabled] = useState(false)
  const { handleGenerate, downloadable, generating, downloadLink } = useReport({
    accountId: accountId,
    reportKlass: "Csv::LearnerExport"
  })

  const [createPresignedUrl] = useMutation(CREATE_PRESIGNED_URL, {
    onError: error => {
      const message = error.message.replace("GraphQL error:", "").trim()
      setErrors([message])
      setUploadDisabled(false)
    }
  })
  const [
    pollBatchStatus,
    { called, loading, data, stopPolling }
  ] = useLazyQuery(POLL_BATCH_STATUS, {
    pollInterval: 1000
  })
  const [bulkCreateLearners] = useMutation(BULK_CREATE_LEARNERS, {
    onCompleted: ({ bulkCreateLearners }) => {
      // Error catching here, a batchId should only be returned if we think
      // eveeything will be successful.
      if (bulkCreateLearners.batchId) {
        pollBatchStatus({ variables: { id: bulkCreateLearners.batchId } })
      } else {
        setErrors(bulkCreateLearners.errors)
        setUploadDisabled(false)
      }
    },
    onError: () => {
      setUploadDisabled(false)
    }
  })

  const batchStatusAvailable = called && !loading && data && data.batchStatus

  if (batchStatusAvailable) {
    if (data.batchStatus.completed) {
      stopPolling()
      if (uploadDisabled) {
        setUploadDisabled(false)
      }
    }
  }

  const onDrop = files => {
    files.forEach(file => {
      setUploadDisabled(true)
      setErrors([])
      const mutation = createPresignedUrl({
        variables: {
          filename: file.name,
          filetype: "text/csv" // Windows doesn't like to pass csv file type through
        }
      })
      mutation.then(data => {
        const privateUrl = data.data.createPresignedUrl.privateUrl
        uploadToSignedURL(file, privateUrl, privateUrl, importLearners)
      })
    })
  }

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    accept: {
      "text/csv": []
    }
  })

  const importLearners = url => {
    bulkCreateLearners({ variables: { url: url } })
  }

  const learnerExportLabel = downloadable
    ? "Download learner export"
    : generating
    ? "Generating learner export..."
    : "Generate learner export"

  const handleReportClick = () => {
    if (!downloadable) {
      handleGenerate()
    }
  }

  return (
    <div>
      <Heading level={2}>Bulk upload learners</Heading>
      <Box paddingTop="small">
        <Text tone="dark">
          Useful for adding many learners at once. Simply download the sample
          CSV or an export of your learner list. Then modify, save, and upload
          when ready.
        </Text>
      </Box>

      <FormGroup>
        <DownloadLink href={"/yarno_bulk_user_upload.csv"} download>
          Download sample
        </DownloadLink>
        <DownloadLink
          href={generating ? "javascript:void(0)" : downloadLink}
          download={downloadable}
          onClick={handleReportClick}
          generating={generating.toString()}>
          {learnerExportLabel}
        </DownloadLink>

        <div {...getRootProps()} style={{ display: "none" }}>
          <input {...getInputProps()} />
        </div>

        <Button
          type="button"
          disabled={uploadDisabled}
          onClick={open}
          color="primary"
          label={"Upload a CSV list of learners"}
          fullWidth
        />
      </FormGroup>

      <Errors>
        {errors.map((i, index) => {
          return <ErrorRow key={index}>{i}</ErrorRow>
        })}
      </Errors>

      {batchStatusAvailable && (
        <React.Fragment>
          <Box paddingBottom="medium">
            <Text>Bulk upload status</Text>
          </Box>
          <ProgressBar percentage={data.batchStatus.percentageComplete} />
          {data.batchStatus.completed && (
            <Text tone="dark">
              {data.batchStatus.total} learners imported. Please refresh the
              page.
            </Text>
          )}
        </React.Fragment>
      )}
    </div>
  )
}

export default BulkLearnerUploader
